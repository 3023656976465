<template>
  <el-container class="shopList">
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
         <el-breadcrumb-item>监管部门</el-breadcrumb-item>
         <el-breadcrumb-item>监管部门列表</el-breadcrumb-item>
        <el-breadcrumb-item >监管员列表</el-breadcrumb-item>
          </el-breadcrumb>
      <div>
        <el-button
                class
                icon="el-icon-plus"
                size="medium"
                type="primary" @click="Add()" >新增监管员</el-button>
        <el-button
          class
          icon="el-icon-plus"
          size="medium"
          type="primary" @click="Adjust()" >人员调整</el-button>
        <el-button class size="medium" @click="$router.back()">返回</el-button>
      </div>
    </el-header>
    <el-main class="pt0">
      <el-row class="box search mt20" :gutter="10">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-col :span="4">
            <el-form-item size="medium">
              <el-input clearable
                placeholder="请输入姓名"
                @change="onSearch(page.user_name)"
                v-model="page.user_name"
                class="input-with-select"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item size="medium">
              <el-select v-model="page.state" clearable placeholder="请选择状态"  @change="onSearch(page.state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="tl mr10">
            <el-form-item size="medium">
              <el-button type="primary" @click="onSearch" class="general_bgc general_border cfff">查询</el-button>
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" fit>
          <el-table-column type="index" width="120" label="序号" align="center"></el-table-column>
          <el-table-column prop="user_name" label="姓名" align="center"></el-table-column>
          <el-table-column prop="user_mobilephone" label="手机号" align="center"></el-table-column>
          <!-- <el-table-column prop="user_town" label="监管区域" align="center"></el-table-column> -->
          <el-table-column label="监管类型" align="center">
            <template slot-scope="scope">{{scope.row.user_tag==1?'监管员':'网格员'}}</template>
          </el-table-column>
          <el-table-column label="操作" fixed="right" width="120px" align="center">
            <template slot-scope="scope">
              <el-button type="text" class="disib red" size="mini" @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr mt20">
          <el-pagination
            v-if="total!=0"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10,20,30,50]"
            :page-size="parseInt(page.pager_openset)"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { mapGetters } from "vuex";
import { session } from "../../../util/util";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        department_id: "",
        state: "",
        user_name: ""
      },
      statusOptions: [
          { label: "全部", value: "" },
        { label: "已启用", value: "1" },
        { label: "已禁用", value: "2" },
        
      ],
      department: {},
      currentPage: 1,
      total: 0,
      tableData: []
    };
  },
  computed: {
    ...mapGetters({
      supervisorPage: "department/supervisorPage"
    })
  },
  filters: {
    stateFilter(state) {
      const stateMap = {
        "1": "已启用",
        "2": "已禁用"
      };
      return stateMap[state];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用"
      };
      return stateMap[state];
    }
  },
  mounted() {
    this.page = Object.assign({}, this.supervisorPage);
    this.currentPage =parseInt(this.page.pager_offset) / parseInt(this.page.pager_openset) + 1;
     this.page.department_id =session.get('params').department_id?String(session.get('params').department_id):'';
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/department-user/list", params).then(response => {
        this.department = response.data.department;
        this.tableData = response.data.department_user_list;
        this.total = response.data.pager_count;
      });
    },
    resetPage() {
      this.page = {
        pager_offset: "0",
        pager_openset: "10",
        department_id:this.page.department_id,
        state: "",
        user_name: ""
      };
    },
    // 搜索
    onSearch() {
      this.page.pager_offset = "0";
      this.init(this.page);
    },
    onClear() {
      this.resetPage();
      this.init(this.page);
    },
    handleCurrentChange(val) {
      this.page.pager_offset = (val - 1) * this.page.pager_openset + "";
      this.init(this.page);
    },
    handleSizeChange(val) {
      this.page.pager_openset = val + "";
      this.init(this.page);
    },
    Add(){
      session.set('params',{id:'',name:'',department_id:this.page.department_id,department_name:this.department.department_name})
      this.$router.push({name: "UserListAdd1"});
    },

    Adjust(){
      session.set('params1',{department_id:this.page.department_id,department_name:this.department.department_name})
      this.$router.push({path: 'supervisorAdd'})
    },
    //删除
    handleDelete(row) {
      // let { user_id } = row;
      axios.delete("/pc/department-user/delete", {
          department_id: String(this.page.department_id),
          user_id:String(row.user_id)
        })
        .then(response => {
          this.init(this.page);
        });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "SupervisorAdd") {
      this.$store.dispatch("department/setSupervisorPage", this.page);
      next();
    } else {
      this.resetPage();
      this.$store.dispatch("department/setSupervisorPage", this.page);
      next();
    }
  }
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
